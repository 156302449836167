import React, { useEffect, useState } from 'react'
import ImageBase from 'next/legacy/image'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import styled from 'styled-components'
import { update, without } from 'ramda'
import { LazyMotion, m } from 'framer-motion'

import { breakpoints, colors } from '@/styles'
import useInterval from '@/hooks/use-interval'
import useMediaQuery from '@/hooks/use-media-query'
import { Button } from '@/components/button'

import AedisLogo from './LogoImages/aedis-logo.png'
import AWMLogo from './LogoImages/awm-logo.png'
import CogirRealEstateLogo from './LogoImages/cogir-real-estate.png'
import ColdwellLogo from './LogoImages/coldwell-banker.png'
import ConcertLogo from './LogoImages/concert-properties.png'
import CorporateStaysLogo from './LogoImages/corporate-stays.png'
import CozyLogo from './LogoImages/cozystay.png'
import DexterPropertyManagementLogo from './LogoImages/dexter-property-management.png'
import DMSPropertiesLogo from './LogoImages/dms-properties.png'
import DunbarTerraceLogo from './LogoImages/dunbar-terrace-logo.png'
import DunowenLogo from './LogoImages/dunowen.png'
import EaglesonLogo from './LogoImages/eagleson-properties.png'
import EasyRentLogo from './LogoImages/easy-rent.png'
import EcoWorldLogo from './LogoImages/eco-world.png'
import GammonLogo from './LogoImages/gammon.png'
import GECLogo from './LogoImages/gec.png'
import HomelifeLogo from './LogoImages/homelife-peninsula.png'
import JoviLogo from './LogoImages/jovi-logo.png'
import KanaveroLogo from './LogoImages/kanavero.png'
import KlausLogo from './LogoImages/klaus-rode-logo.png'
import LehomesLogo from './LogoImages/lehomes.png'
import LuxmoreLogo from './LogoImages/luxmore.png'
import LuxuryLiving from './LogoImages/luxury-living-logo.png'
import LuxRentalSuitesLogo from './LogoImages/luxury-rental-suites.png'
import MacDonaldLogo from './LogoImages/macdonald-realty.png'
import MartelloTowerLogo from './LogoImages/martello-tower-logo.png'
import MetCapLogo from './LogoImages/metcap.png'
import MindfulLogo from './LogoImages/mindful-logo.png'
import MyDreamRealtyLogo from './LogoImages/my-dream-realty.png'
import NobleandAssociatesLogo from './LogoImages/noble.png'
import OakwynLogo from './LogoImages/oakwyn.png'
import PacificQuorumLogo from './LogoImages/pacific-quorum-logo.png'
import PeterCoppardLogo from './LogoImages/peter-coppard-logo.png'
import PromptonLogo from './LogoImages/prompton-logo.png'
import RaAllianceLogo from './LogoImages/ra-alliance-inc.png'
import RemaxCrestLogo from './LogoImages/remax-crest-realty.png'
import RoyalYorkPropertyManagementLogo from './LogoImages/royal-york-property-management.png'
import SincereLogo from './LogoImages/sincere-real-estate.png'
import SoulHomesLogo from './LogoImages/soul-homes.png'
import SuttonLogo from './LogoImages/sutton-logo.png'
import TangKungLogo from './LogoImages/tang-kung.png'
import TopVisionLogo from './LogoImages/top-vision.png'
import VancouverExtendedStayLogo from './LogoImages/vancouver-extended-stay.png'
import VancouverRentItLogo from './LogoImages/vancouver-rent-it-logo.png'
import VancouverRentalGroup from './LogoImages/vancouver-rental-group.png'
import VancouverRentalLogo from './LogoImages/vancouver-rental-properties.png'
import VancouverLuxuryRentalLogo from './LogoImages/vlr-logo.png'
import WBrothersLogo from './LogoImages/wbrothers-logo.png'
import WesgroupLogo from './LogoImages/wesgroup.png'
import WestwyndLogo from './LogoImages/westwynd-realty.png'
import YVRRealityLogo from './LogoImages/yvr-realty-logo.png'

const loadFeatures = () => import('./features.js').then(res => res.default)

const Wrapper = styled.div`
  background-color: ${colors.background};
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 170px 0;
  align-items: center;

  @media (max-width: ${breakpoints.phoneMax}) {
    padding: 100px 0;
  }
`

const Title = styled.h2`
  color: ${colors.regular};
  font-family: futura-pt-bold, Futura, sans-serif;
  font-weight: 700;
  font-size: 57px;
  letter-spacing: -0.1px;
  line-height: 54px;
  text-align: center;
  padding: 0 30px;
  margin: 0;

  @media (max-width: ${breakpoints.phoneMax}) {
    padding: 0 25px;
    font-size: 34px;
    letter-spacing: -0.09px;
    line-height: 34px;
    text-align: left;
  }
`

const Companies = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 20%);
  grid-auto-rows: 200px;
  gap: 5px 5px;
  margin-top: 65px;
  justify-content: center;
  width: 100%;

  > div {
    height: 100%;
    display: flex;
    align-items: center;
    background-color: ${colors.white};
    position: relative;
  }

  @media (max-width: ${breakpoints.ipadMiniMax}) {
    grid-template-columns: repeat(2, 45%);
    grid-auto-rows: 80px;
  }

  @media (max-width: ${breakpoints.phoneMax}) {
    margin-top: 50px;
  }
`

const ButtonWrapper = styled.div`
  margin-top: 80px;
  @media (max-width: ${breakpoints.phoneMax}) {
    margin-top: 50px;
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
`

const ButtonText = styled.span`
  text-align: center;
  min-width: 160px;
`

const Image = ({ src, alt, layout, objectFit, ...rest }) => (
  <m.div {...rest}>
    <ImageBase src={src} alt={alt} layout={layout} objectFit={objectFit} />
  </m.div>
)

const variants = {
  start: {
    opacity: 0,
    transform: 'scale(0)'
  },
  enter: {
    opacity: 1,
    transform: 'scale(1)'
  },
  exit: {
    opacity: 0,
    transform: 'scale(1)',
    transitionEnd: { display: 'none' }
  }
}

const animation = {
  initial: 'start',
  animate: 'enter',
  exit: 'exit',
  transition: { ['type']: 'spring', damping: 20, mass: 1, stiffness: 180 },
  variants
}

const LOGOS = [
  <Image
    key="company-logo-1"
    src={KlausLogo}
    alt="Century 21"
    layout="fill"
    objectFit="contain"
    {...animation}
  />,
  <Image
    key="company-logo-2"
    src={JoviLogo}
    alt="Jovi"
    layout="fill"
    objectFit="contain"
    {...animation}
  />,
  <Image
    key="company-logo-3"
    src={TopVisionLogo}
    alt="Top Vision"
    layout="fill"
    objectFit="contain"
    {...animation}
  />,
  <Image
    key="company-logo-4"
    src={WestwyndLogo}
    alt="Westwynd Realty"
    layout="fill"
    objectFit="contain"
    {...animation}
  />,
  <Image
    key="company-logo-5"
    src={MindfulLogo}
    alt="Mindful"
    layout="fill"
    objectFit="contain"
    {...animation}
  />,
  <Image
    key="company-logo-6"
    src={SuttonLogo}
    alt="Sutton"
    layout="fill"
    objectFit="contain"
    {...animation}
  />,
  <Image
    key="company-logo-7"
    src={LuxuryLiving}
    alt="Luxury Living"
    layout="fill"
    objectFit="contain"
    {...animation}
  />,
  <Image
    key="company-logo-8"
    src={AedisLogo}
    alt="Aedis"
    layout="fill"
    objectFit="contain"
    {...animation}
  />,
  <Image
    key="company-logo-9"
    src={PeterCoppardLogo}
    alt="Peter Coppard"
    layout="fill"
    objectFit="contain"
    {...animation}
  />,
  <Image
    key="company-logo-10"
    src={MartelloTowerLogo}
    alt="Martello Tower"
    layout="fill"
    objectFit="contain"
    {...animation}
  />,
  <Image
    key="company-logo-11"
    src={DunbarTerraceLogo}
    alt="Dunbar Terrace"
    layout="fill"
    objectFit="contain"
    {...animation}
  />,
  <Image
    key="company-logo-12"
    src={AWMLogo}
    alt="AWM"
    layout="fill"
    objectFit="contain"
    {...animation}
  />,
  <Image
    key="company-logo-13"
    src={PacificQuorumLogo}
    alt="Pacific Quorum"
    layout="fill"
    objectFit="contain"
    {...animation}
  />,
  <Image
    key="company-logo-14"
    src={PromptonLogo}
    alt="Prompton"
    layout="fill"
    objectFit="contain"
    {...animation}
  />,
  <Image
    key="company-logo-15"
    src={VancouverRentItLogo}
    alt="Vancouver Rent It"
    layout="fill"
    objectFit="contain"
    {...animation}
  />,
  <Image
    key="company-logo-16"
    src={VancouverRentalGroup}
    alt="Vancouver Rental Group"
    layout="fill"
    objectFit="contain"
    {...animation}
  />,
  <Image
    key="company-logo-17"
    src={WBrothersLogo}
    alt="W Brothers"
    layout="fill"
    objectFit="contain"
    {...animation}
  />,
  <Image
    key="company-logo-18"
    src={YVRRealityLogo}
    alt="YVR Reality"
    layout="fill"
    objectFit="contain"
    {...animation}
  />,
  <Image
    key="company-logo-19"
    src={ColdwellLogo}
    alt="Coldwell Banker"
    layout="fill"
    objectFit="contain"
    {...animation}
  />,
  <Image
    key="company-logo-20"
    src={ConcertLogo}
    alt="Concert Properties"
    layout="fill"
    objectFit="contain"
    {...animation}
  />,
  <Image
    key="company-logo-21"
    src={CozyLogo}
    alt="Cozystay"
    layout="fill"
    objectFit="contain"
    {...animation}
  />,
  <Image
    key="company-logo-22"
    src={DunowenLogo}
    alt="Dunowen Properties"
    layout="fill"
    objectFit="contain"
    {...animation}
  />,
  <Image
    key="company-logo-23"
    src={EasyRentLogo}
    alt="Easy Rent"
    layout="fill"
    objectFit="contain"
    {...animation}
  />,
  <Image
    key="company-logo-24"
    src={EaglesonLogo}
    alt="Eagleson Properties.png"
    layout="fill"
    objectFit="contain"
    {...animation}
  />,
  <Image
    key="company-logo-25"
    src={EcoWorldLogo}
    alt="Eco-World Property Management"
    layout="fill"
    objectFit="contain"
    {...animation}
  />,
  <Image
    key="company-logo-26"
    src={GammonLogo}
    alt="Gammon Living"
    layout="fill"
    objectFit="contain"
    {...animation}
  />,
  <Image
    key="company-logo-27"
    src={GECLogo}
    alt="GEC"
    layout="fill"
    objectFit="contain"
    {...animation}
  />,
  <Image
    key="company-logo-28"
    src={OakwynLogo}
    alt="Oakwyn Realty"
    layout="fill"
    objectFit="contain"
    {...animation}
  />,
  <Image
    key="company-logo-29"
    src={HomelifeLogo}
    alt="Homelife Peninsula"
    layout="fill"
    objectFit="contain"
    {...animation}
  />,
  <Image
    key="company-logo-30"
    src={KanaveroLogo}
    alt="Kanavero Real Estate"
    layout="fill"
    objectFit="contain"
    {...animation}
  />,
  <Image
    key="company-logo-31"
    src={LehomesLogo}
    alt="LeHomes"
    layout="fill"
    objectFit="contain"
    {...animation}
  />,
  <Image
    key="company-logo-32"
    src={LuxmoreLogo}
    alt="Luxmore Realty"
    layout="fill"
    objectFit="contain"
    {...animation}
  />,
  <Image
    key="company-logo-33"
    src={LuxRentalSuitesLogo}
    alt="Luxury Rental Suites"
    layout="fill"
    objectFit="contain"
    {...animation}
  />,
  <Image
    key="company-logo-34"
    src={MacDonaldLogo}
    alt="MacDonald Realty"
    layout="fill"
    objectFit="contain"
    {...animation}
  />,
  <Image
    key="company-logo-35"
    src={MetCapLogo}
    alt="MetCap"
    layout="fill"
    objectFit="contain"
    {...animation}
  />,
  <Image
    key="company-logo-36"
    src={RemaxCrestLogo}
    alt="Remax Crest"
    layout="fill"
    objectFit="contain"
    {...animation}
  />,
  <Image
    key="company-logo-37"
    src={RaAllianceLogo}
    alt="Realty Alliance"
    layout="fill"
    objectFit="contain"
    {...animation}
  />,
  <Image
    key="company-logo-38"
    src={SincereLogo}
    alt="Sincere Real Estate"
    layout="fill"
    objectFit="contain"
    {...animation}
  />,
  <Image
    key="company-logo-39"
    src={SoulHomesLogo}
    alt="Soul Homes"
    layout="fill"
    objectFit="contain"
    {...animation}
  />,
  <Image
    key="company-logo-40"
    src={TangKungLogo}
    alt="Tang and Kung"
    layout="fill"
    objectFit="contain"
    {...animation}
  />,
  <Image
    key="company-logo-41"
    src={TopVisionLogo}
    alt="Top Vision"
    layout="fill"
    objectFit="contain"
    {...animation}
  />,
  <Image
    key="company-logo-42"
    src={WesgroupLogo}
    alt="Wesgroup"
    layout="fill"
    objectFit="contain"
    {...animation}
  />,
  <Image
    key="company-logo-43"
    src={VancouverRentalLogo}
    alt="Vancouver Rental Properties"
    layout="fill"
    objectFit="contain"
    {...animation}
  />,
  <Image
    key="company-logo-44"
    src={VancouverLuxuryRentalLogo}
    alt="Vancouver Luxury Rental"
    layout="fill"
    objectFit="contain"
    {...animation}
  />,
  <Image
    key="company-logo-45"
    src={CogirRealEstateLogo}
    alt="Cogir Real Estate"
    layout="fill"
    objectFit="contain"
    {...animation}
  />,
  <Image
    key="company-logo-46"
    src={DMSPropertiesLogo}
    alt="DMS Properties"
    layout="fill"
    objectFit="contain"
    {...animation}
  />,
  <Image
    key="company-logo-47"
    src={CorporateStaysLogo}
    alt="Corporate Stays"
    layout="fill"
    objectFit="contain"
    {...animation}
  />,
  <Image
    key="company-logo-48"
    src={VancouverExtendedStayLogo}
    alt="Vancouver Extended Stay"
    layout="fill"
    objectFit="contain"
    {...animation}
  />,
  <Image
    key="company-logo-49"
    src={MyDreamRealtyLogo}
    alt="My Dream Realty"
    layout="fill"
    objectFit="contain"
    {...animation}
  />,
  <Image
    key="company-logo-50"
    src={NobleandAssociatesLogo}
    alt="Noble and Associates"
    layout="fill"
    objectFit="contain"
    {...animation}
  />,
  <Image
    key="company-logo-51"
    src={DexterPropertyManagementLogo}
    alt="Dexter Property Management"
    layout="fill"
    objectFit="contain"
    {...animation}
  />,
  <Image
    key="company-logo-52"
    src={RoyalYorkPropertyManagementLogo}
    alt="Royal York Property Management"
    layout="fill"
    objectFit="contain"
    {...animation}
  />
]

const INTERVAL = 2000

const Landlords = () => {
  const { t } = useTranslation('home')
  const isMobile = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)

  const [displayCount, setDisplayCount] = useState()
  const [activeLogos, setActiveLogos] = useState()

  useEffect(() => {
    const count = isMobile ? 10 : 12
    setDisplayCount(count)
    setActiveLogos(LOGOS.slice(0, count))
  }, [isMobile])

  const updateLogos = currentLogos => {
    const unusedLogos = without(currentLogos, LOGOS)
    const oldIndex = Math.floor(Math.random() * displayCount)
    const newIndex = Math.floor(Math.random() * unusedLogos.length)
    return update(oldIndex, unusedLogos[newIndex], currentLogos)
  }

  useInterval(() => {
    setActiveLogos(currentLogos => updateLogos(currentLogos))
  }, INTERVAL)
  return (
    <Wrapper>
      <Title>{t('home:Companies that have taken the next step.')}</Title>
      <LazyMotion features={loadFeatures}>
        <Companies>{activeLogos}</Companies>
      </LazyMotion>
      <ButtonWrapper>
        <StyledLink href="/landlords" passHref>
          <Button
            id="home-landlords-joinus-click"
            theme="secondary"
            radius={50}
          >
            <ButtonText>{t('home:Join us')}</ButtonText>
          </Button>
        </StyledLink>
      </ButtonWrapper>
    </Wrapper>
  )
}

export default React.memo(Landlords)
